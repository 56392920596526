<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <!-- Navbar -->
      <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <div class="content-header">
          <div class="container-fluid">
            <div class="row mb-2">
              <div class="col-sm-12">
                <h1 class="m-0 text-dark text-center">
                  Bienvenido {{ this.$store.state.user.name }}...
                </h1>
              </div>
              <!-- /.container-fluid -->
            </div>
            <!-- /.content-header -->

            <!-- Main content -->
            <section class="content">
              <div class="container-fluid">
                <div class="card">
                  <div class="card-header">
                    <i class="fa fa-align-justify"></i> Selecciona un modulo
                    para iniciar a trabajar
                  </div>

                  <!-- /.card-header -->
                  <div class="card-body">
                    <div class="row">
                      <div class="col" v-if="permisoLineaNegocio(2)">
                        <!-- Widget: user widget style 1 -->
                        <div class="card card-widget widget-user">
                          <!-- Add the bg color to the header using any of the bg-* classes -->
                          <div
                            class="widget-user-header text-white bg-frontera"
                          >
                            <h3 class="widget-user-username text-right">
                              Pegaso
                            </h3>
                            <h5 class="widget-user-desc text-right">
                              Transporte Hidrocarburos Nacional
                            </h5>
                          </div>

                          <div class="card-footer pt-3">
                            <div class="row">
                              <div class="col-sm-12 border-right">
                                <button
                                  @click="irLineaNegocio(2)"
                                  class="
                                    btn btn-lg btn-block btn-outline-primary
                                    mt-2
                                  "
                                >
                                  <i class="fa fa-eye"></i> Ver más
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col" v-if="permisoLineaNegocio(3)">
                        <!-- Widget: user widget style 1 -->
                        <div class="card card-widget widget-user">
                          <!-- Add the bg color to the header using any of the bg-* classes -->
                          <div
                            class="widget-user-header text-white bg-frontera"
                            style="
                              background: linear-gradient(
                                to bottom right,
                                #ff1c79,
                                #001871
                              );
                            "
                          >
                            <h3 class="widget-user-username text-right">
                              Pegaso T.I.F
                            </h3>
                            <h5 class="widget-user-desc text-right">
                              Transporte Interno de Fluidos
                            </h5>
                          </div>

                          <div class="card-footer pt-3">
                            <div class="row">
                              <div class="col-sm-12 border-right">
                                <button
                                  @click="irLineaNegocio(3)"
                                  class="
                                    btn btn-lg btn-block btn-outline-primary
                                    mt-2
                                  "
                                >
                                  <i class="fa fa-eye"></i> Ver más
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col" v-if="permisoLineaNegocio(4)">
                        <!-- Widget: user widget style 1 -->
                        <div class="card card-widget widget-user">
                          <!-- Add the bg color to the header using any of the bg-* classes -->
                          <div
                            class="widget-user-header text-white bg-frontera"
                            style="
                              background: linear-gradient(
                                to bottom right,
                                #ff1c79,
                                #001871
                              );
                            "
                          >
                            <h3 class="widget-user-username text-right">
                              Pegaso Carga seca
                            </h3>
                            <h5 class="widget-user-desc text-right">
                              Transporte carga seca
                            </h5>
                          </div>

                          <div class="card-footer pt-3">
                            <div class="row">
                              <div class="col-sm-12 border-right">
                                <button
                                  @click="irLineaNegocio(4)"
                                  class="
                                    btn btn-lg btn-block btn-outline-primary
                                    mt-2
                                  "
                                >
                                  <i class="fa fa-eye"></i> Ver más
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col" v-if="permisoLineaNegocio(5)">
                        <!-- Widget: user widget style 1 -->
                        <div class="card card-widget widget-user">
                          <!-- Add the bg color to the header using any of the bg-* classes -->
                          <div
                            class="widget-user-header text-white bg-frontera"
                            style="
                              background: linear-gradient(
                                to bottom right,
                                #ff1c79,
                                #001871
                              );
                            "
                          >
                            <h3 class="widget-user-username text-right">
                              Pegaso Voy por Ti
                            </h3>
                            <h5 class="widget-user-desc text-right">
                              Transporte especial de pasajeros
                            </h5>
                          </div>

                          <div class="card-footer pt-3">
                            <div class="row">
                              <div class="col-sm-12 border-right">
                                <button
                                  @click="irLineaNegocio(5)"
                                  class="
                                    btn btn-lg btn-block btn-outline-primary
                                    mt-2
                                  "
                                >
                                  <i class="fa fa-eye"></i> Ver más
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col" v-if="permisoLineaNegocio(1)">
                        <!-- <div class="col-md-3 col-sm-6"> -->
                        <!-- Widget: user widget style 1 -->
                        <div class="card card-widget widget-user">
                          <!-- Add the bg color to the header using any of the bg-* classes -->
                          <div
                            class="widget-user-header text-white bg-frontera"
                            style="
                              background: linear-gradient(
                                to bottom right,
                                #ff1c79,
                                #001871
                              );
                            "
                          >
                            <h3 class="widget-user-username text-right">
                              Administrativo
                            </h3>
                            <h5 class="widget-user-desc text-right">
                              Modulo Administrativo
                            </h5>
                          </div>

                          <div class="card-footer pt-3">
                            <div class="row">
                              <!--<<div class="col-sm-4 border-right">
                                  <div class="description-block">
                                    <h5 class="description-header">35</h5>
                                    <span class="description-text">Vehiculos</span>
                                  </div>
                                </div>-->
                              <div class="col-sm-12 border-right">
                                <button
                                  @click="irLineaNegocio(1)"
                                  class="
                                    btn btn-lg btn-block btn-outline-primary
                                    mt-2
                                  "
                                >
                                  <i class="fa fa-eye"></i> Ver más
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- </div> -->
                      </div>
                      <div class="col" v-if="permisoLineaNegocio(10)">
                        <!-- <div class="col-md-6 col-sm-6"> -->
                        <!-- Widget: user widget style 1 -->
                        <div class="card card-widget widget-user">
                          <!-- Add the bg color to the header using any of the bg-* classes -->
                          <div
                            class="widget-user-header text-white bg-frontera"
                            style="
                              background: linear-gradient(
                                to bottom right,
                                #ff1c79,
                                #001871
                              );
                            "
                          >
                            <h3 class="widget-user-username text-right">
                              Pegaso Solicitudes
                            </h3>
                            <h5 class="widget-user-desc text-right">
                              TIF / TEP / Carga Seca / Seguridad Víal
                            </h5>
                          </div>
                          <div class="card-footer pt-3">
                            <div class="row">
                              <!--<<div class="col-sm-4 border-right">
                                  <div class="description-block">
                                    <h5 class="description-header">35</h5>
                                    <span class="description-text">Vehiculos</span>
                                  </div>
                                </div>-->
                              <div class="col-sm-12 border-right">
                                <button
                                  @click="irLineaNegocio(10)"
                                  class="
                                    btn btn-lg btn-block btn-outline-primary
                                    mt-2
                                  "
                                >
                                  <i class="fa fa-eye"></i> Ver más
                                </button>
                              </div>
                            </div>
                            <!-- </div> -->
                          </div>
                        </div>
                      </div>
                      <div class="col" v-if="permisoLineaNegocio(8)">
                        <!-- <div class="col-md-6 col-sm-6"> -->
                        <!-- Widget: user widget style 1 -->
                        <div class="card card-widget widget-user">
                          <!-- Add the bg color to the header using any of the bg-* classes -->
                          <div
                            class="widget-user-header text-white bg-frontera"
                            style="
                              background: linear-gradient(
                                to bottom right,
                                #ff1c79,
                                #001871
                              );
                            "
                          >
                            <h3 class="widget-user-username text-right">
                              Pegaso 2.0 - Seguridad Vial
                            </h3>
                            <h5 class="widget-user-desc text-right">
                              HSE
                            </h5>
                          </div>
                          <div class="card-footer pt-3">
                            <div class="row">
                              <!--<<div class="col-sm-4 border-right">
                                  <div class="description-block">
                                    <h5 class="description-header">35</h5>
                                    <span class="description-text">Vehiculos</span>
                                  </div>
                                </div>-->
                              <div class="col-sm-12 border-right">
                                <button
                                  @click="irLineaNegocio(8)"
                                  class="
                                    btn btn-lg btn-block btn-outline-primary
                                    mt-2
                                  "
                                >
                                  <i class="fa fa-eye"></i> Ver más
                                </button>
                              </div>
                            </div>
                            <!-- </div> -->
                          </div>
                        </div>
                      </div>
                      <div class="col" v-if="permisoLineaNegocio(11)">
                        <!-- <div class="col-md-6 col-sm-6"> -->
                        <!-- Widget: user widget style 1 -->
                        <div class="card card-widget widget-user">
                          <!-- Add the bg color to the header using any of the bg-* classes -->
                          <div
                            class="widget-user-header text-white bg-frontera"
                            style="
                              background: linear-gradient(
                                to bottom right,
                                #ff1c79,
                                #001871
                              );
                            "
                          >
                            <h3 class="widget-user-username text-right">
                              Pegaso 2.0 - Comercialización
                            </h3>
                            <h5 class="widget-user-desc text-right">
                              Modulo Comercial
                            </h5>
                          </div>
                          <div class="card-footer pt-3">
                            <div class="row">
                              <!--<<div class="col-sm-4 border-right">
                                  <div class="description-block">
                                    <h5 class="description-header">35</h5>
                                    <span class="description-text">Vehiculos</span>
                                  </div>
                                </div>-->
                              <div class="col-sm-12 border-right">
                                <button
                                  @click="irLineaNegocio(11)"
                                  class="
                                    btn btn-lg btn-block btn-outline-primary
                                    mt-2
                                  "
                                >
                                  <i class="fa fa-eye"></i> Ver más
                                </button>
                              </div>
                            </div>
                            <!-- </div> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.center-block {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
<script>
import Loading from "../components/Loading";
export default {
  name: "Home",
  components: {
    Loading,
  },
  data() {
    return {
      cargando: false,
    };
  },

  methods: {
    toggleBodyClass(addRemoveClass, className) {
      const el = document.body;

      if (addRemoveClass === "addClass") {
        el.classList.add(className);
      } else {
        el.classList.remove(className);
      }
    },
    async irLineaNegocio(linea_negocio) {
      this.cargando = true;
      await this.$store.dispatch("getMenus", linea_negocio);
      await this.$store.dispatch("getRoutes", this.$store.getters.getMenus);
      await this.$store.dispatch("setRoutes");
      if (linea_negocio == 1) {
        this.cargando = false;
        return this.$router.replace("/Admin");
      }
      if (linea_negocio == 2) {
        this.cargando = false;
        return this.$router.replace("/Hidrocarburos");
      }
      if (linea_negocio == 5) {
        this.cargando = false;
        return this.$router.replace("/tep");
      }
      if (linea_negocio == 3) {
        this.cargando = false;
        return this.$router.replace("/tif");
      }
      if (linea_negocio == 4) {
        this.cargando = false;
        return this.$router.replace("/cs");
      }
      if (linea_negocio == 8) {
        this.cargando = false;
        return this.$router.replace("/Hse");
      }

      if (linea_negocio == 10) {
        this.cargando = false;
        return this.$router.replace("/FuncionariosFrontera");
      }

      if (linea_negocio == 11) {
        this.cargando = false;
        return this.$router.replace("/Comercializacion");
      }
    },

    permisoLineaNegocio(linea_negocio) {
      let lineasUser = this.$store.state.user.lineas_negocio;
      return (
        lineasUser.find((linea) => linea.id === linea_negocio) !== undefined
      );
    },
  },
  mounted() {
    this.toggleBodyClass("addClass", "sidebar-collapse");
    this.toggleBodyClass("removeClass", "sidebar-mini");
  },
  destroyed() {
    this.toggleBodyClass("removeClass", "sidebar-collapse");
    this.toggleBodyClass("addClass", "sidebar-mini");
  },
};
</script>
